import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// import { MENU_TOKEN } from '@config/menu';
import menus from '@core/services/mock/menus';
import { Menu } from '@core/services/types';
import { BaseHttpService } from '@services/base-http.service';
// import { MenusService } from '@services/system/menus.service';
import { Auth, UserInfo } from '@store/common-store/userInfo.service';

export interface UserLogin {
  name: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  http = inject(BaseHttpService);
  // private menus = inject(MENU_TOKEN);

  public login(params: UserLogin): Observable<UserInfo> {
    return this.http.post('/adminLogin/pwdLogin', params, { needSuccessInfo: false });
  }

  public getMenuByUserId(authList: Auth[]): Observable<Menu[]> {
    // 如果是静态菜单，就把下面注释放开
    // return of(this.menus);
    if (Array.isArray(authList) && authList.some(next => next.authRole === 'platform_n')) {
      return of(menus.slice(0, 4)) as any;
    }
    return of(menus) as any;
  }
}
