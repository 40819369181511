const menus = [
  {
    id: 220,
    menuName: '仪表盘',
    code: 'default:panel',
    fatherId: 0,
    orderNum: 1,
    path: '/default/panel',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'dashboard',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 221,
    menuName: '预警仪表台',
    code: 'default:panel:forewarning:index',
    fatherId: 220,
    orderNum: 1,
    path: '/default/panel/forewarning/index',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'warning',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 223,
    menuName: '保单列表',
    code: 'default:panel:forewarning:task-index',
    fatherId: 220,
    orderNum: 2,
    path: '/default/panel/forewarning/task',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'borderless-table',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 222,
    menuName: '协保员保单列表',
    code: 'default:panel:forewarning:task',
    fatherId: 220,
    orderNum: 2,
    path: '/default/panel/forewarning/index/:adminId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'borderless-table',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 224,
    menuName: '核验记录',
    code: 'default:panel:forewarning:task:check-record',
    fatherId: 220,
    orderNum: 2,
    path: '/default/panel/forewarning/task/checkRecord/:taskNo',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'borderless-table',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 200,
    menuName: '保险公司',
    code: 'default:company',
    fatherId: 0,
    orderNum: 1,
    path: '/default/company',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'bank',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 201,
    menuName: '保险公司列表',
    code: 'default:company:list',
    fatherId: 200,
    orderNum: 1,
    path: '/default/company/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 210,
    menuName: '险种列表',
    code: 'default:company:detail',
    fatherId: 201,
    orderNum: 1,
    path: '/default/company/list/:id',
    menuType: 'C',
    visible: false,
    status: true,
    icon: '',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 212,
    menuName: '费率列表',
    code: 'default:company:list:epCode:insureId',
    fatherId: 210,
    orderNum: 1,
    path: '/default/company/list/:epCode/:insureId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: '',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 234,
    menuName: '补贴列表',
    code: 'default:company:list:epCode:subsidy:insureId',
    fatherId: 210,
    orderNum: 1,
    path: '/default/company/list/:epCode/subsidy/:insureId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: '',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 213,
    menuName: '新建保险公司',
    code: 'default:company:create',
    fatherId: 200,
    orderNum: 2,
    path: '/default/company/create',
    menuType: 'C',
    visible: false,
    status: true,
    icon: '',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 202,
    menuName: '管理员',
    code: 'default:administrator',
    fatherId: 0,
    orderNum: 2,
    path: '/default/administrator',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'team',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 226,
    menuName: '保险公司管理员',
    code: 'default:administrator:insure:list',
    fatherId: 202,
    orderNum: 1,
    path: '/default/administrator/insure/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 227,
    menuName: '保险公司管理员权限管理',
    code: 'default:administrator:insure:detail',
    fatherId: 226,
    orderNum: 1,
    path: '/default/administrator/insure/list/:adminId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 203,
    menuName: '防疫改良管理员',
    code: 'default:administrator:vac:list',
    fatherId: 202,
    orderNum: 2,
    path: '/default/administrator/vac/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 225,
    menuName: '防疫改良管理员权限管理',
    code: 'default:administrator:vac:detail',
    fatherId: 203,
    orderNum: 1,
    path: '/default/administrator/vac/list/:adminId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 228,
    menuName: '养殖场管理员',
    code: 'default:administrator:farm:list',
    fatherId: 202,
    orderNum: 3,
    path: '/default/administrator/farm/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 229,
    menuName: '养殖场管理员权限管理',
    code: 'default:administrator:farm:detail',
    fatherId: 228,
    orderNum: 1,
    path: '/default/administrator/farm/list/:adminId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 230,
    menuName: '银行管理员',
    code: 'default:administrator:bank:list',
    fatherId: 202,
    orderNum: 4,
    path: '/default/administrator/bank/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 231,
    menuName: '银行管理员权限管理',
    code: 'default:administrator:bank:detail',
    fatherId: 230,
    orderNum: 1,
    path: '/default/administrator/bank/list/:adminId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 232,
    menuName: '政府管理员',
    code: 'default:administrator:gov:list',
    fatherId: 202,
    orderNum: 5,
    path: '/default/administrator/gov/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 233,
    menuName: '政府管理员权限管理',
    code: 'default:administrator:gov:detail',
    fatherId: 232,
    orderNum: 1,
    path: '/default/administrator/gov/list/:adminId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 204,
    menuName: '客户信息',
    code: 'default:customer',
    fatherId: 0,
    orderNum: 2,
    path: '/default/customer',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'user',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 205,
    menuName: '客户列表',
    code: 'default:customer:list',
    fatherId: 204,
    orderNum: 1,
    path: '/default/customer/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 215,
    menuName: '客户详情',
    code: 'default:customer:detail',
    fatherId: 204,
    orderNum: 1,
    path: '/default/customer/list/:userId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: '',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 206,
    menuName: '任务单',
    code: 'default:task',
    fatherId: 0,
    orderNum: 2,
    path: '/default/task',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'container',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 207,
    menuName: '任务单列表',
    code: 'default:task:list',
    fatherId: 206,
    orderNum: 1,
    path: '/default/task/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 211,
    menuName: '任务单详情',
    code: 'default:task:detail',
    fatherId: 207,
    orderNum: 1,
    path: '/default/task/list/:taskNo',
    menuType: 'C',
    visible: false,
    status: true,
    icon: '',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 208,
    menuName: '牛只信息',
    code: 'default:cow',
    fatherId: 0,
    orderNum: 2,
    path: '/default/cow',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'gold',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 209,
    menuName: '牛只列表',
    code: 'default:cow:list',
    fatherId: 208,
    orderNum: 1,
    path: '/default/cow/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 214,
    menuName: '牛只详情',
    code: 'default:cow:list:detail',
    fatherId: 209,
    orderNum: 1,
    path: '/default/cow/list/:epCode/:subjectCode',
    menuType: 'C',
    visible: false,
    status: true,
    icon: '',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 234,
    menuName: '养殖场',
    code: 'default:farm',
    fatherId: 0,
    orderNum: 2,
    path: '/default/farm',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'gold',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 235,
    menuName: '养殖场统计',
    code: 'default:farm:list',
    fatherId: 234,
    orderNum: 1,
    path: '/default/farm/statistics/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 236,
    menuName: '养殖场告警',
    code: 'default:farm:list:warn',
    fatherId: 235,
    orderNum: 1,
    path: '/default/farm/statistics/list/warn/:farmId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 237,
    menuName: '养殖场棚舍',
    code: 'default:farm:hut:list',
    fatherId: 235,
    orderNum: 1,
    path: '/default/farm/statistics/list/hut/list/:farmId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 238,
    menuName: '养殖场棚舍分栏信息',
    code: 'default:farm:hut:list:she',
    fatherId: 237,
    orderNum: 1,
    path: '/default/farm/statistics/list/hut/list/:farmId/:shedId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 239,
    menuName: '养殖场棚舍分栏牛只信息',
    code: 'default:farm:hut:list:she:filed',
    fatherId: 238,
    orderNum: 1,
    path: '/default/farm/statistics/list/hut/list/:farmId/:shedId/:fieldId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 240,
    menuName: '养殖场棚舍分栏牛只详情',
    code: 'default:farm:hut:list:she:filed:cowId',
    fatherId: 239,
    orderNum: 1,
    path: '/default/farm/statistics/list/hut/list/:farmId/:shedId/:fieldId/:cowId',
    menuType: 'C',
    visible: false,
    status: true,
    icon: 'unordered-list',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 216,
    menuName: '系统',
    code: 'default:system',
    fatherId: 0,
    orderNum: 2,
    path: '/default/system',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'appstore',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 217,
    menuName: '日志列表',
    code: 'default:system:logs:list',
    fatherId: 216,
    orderNum: 1,
    path: '/default/system/logs/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'read',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 218,
    menuName: '云市场接口列表',
    code: 'default:system:apis:list',
    fatherId: 216,
    orderNum: 1,
    path: '/default/system/apis/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'borderless-table',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  },
  {
    id: 219,
    menuName: '地区管理',
    code: 'default:system:area:list',
    fatherId: 216,
    orderNum: 1,
    path: '/default/system/area/list',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'build',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null
  }
];

export default menus;
