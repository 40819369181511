import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// import { JwtHelperService } from '@auth0/angular-jwt';

export interface Auth {
  areaId: number;
  areaName: string;
  authRole: string;
  authRoleName: string;
  epCode: string;
  farmId: number;
  mapUrl: string;
}

export interface UserInfo {
  adminId: number;
  adminName: string;
  authList: Auth[];
  token: string;
  authCode: string[];
}

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private userInfo$ = new BehaviorSubject<UserInfo>({ adminId: -1, adminName: '', authList: [], token: '', authCode: [] });

  // parsToken(token: string): UserInfo {
  //   const helper = new JwtHelperService();
  //   try {
  //     const { rol, userId } = helper.decodeToken(token);
  //     return {
  //       userId,
  //       authCode: rol.split(',')
  //     };
  //   } catch (e) {
  //     return {
  //       userId: -1,
  //       authCode: []
  //     };
  //   }
  // }

  setUserInfo(userInfo: UserInfo): void {
    this.userInfo$.next(userInfo);
  }

  getUserInfo(): Observable<UserInfo> {
    return this.userInfo$.asObservable();
  }

  getUserInfoAsync(): UserInfo {
    return this.userInfo$.getValue();
  }
}
