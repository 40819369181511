import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LoginInOutService } from '@core/services/common/login-in-out.service';
import { serviceApiUrl } from '@env/environment';
import { UserInfoService } from '@store/common-store/userInfo.service';
import dayjs from 'dayjs';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import qs from 'qs';

export interface HttpCustomConfig {
  needSuccessInfo?: boolean; // 是否需要"操作成功"提示
  showLoading?: boolean; // 是否需要loading
  otherUrl?: boolean; // 是否是第三方接口
}

export interface PublicRequestParams {
  epCode: string;
  systemId: string;
  operatorId: number;
  token: string;
  traceId: string;
  clientTime: string;
  clientVersion: string;
  scene: string;
  sign: string;
}

export interface ActionResult<T> {
  code: number;
  msg: string;
  data: T;
}

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  private uri: string;
  private http = inject(HttpClient);
  private message = inject(NzMessageService);
  private userService = inject(UserInfoService);
  private loginService: LoginInOutService | undefined;

  protected constructor(injector: Injector) {
    this.uri = serviceApiUrl;
    setTimeout(() => (this.loginService = injector.get(LoginInOutService)));
  }

  private getRequestPublicParams(): PublicRequestParams {
    const userInfo = this.userService.getUserInfoAsync();
    return {
      epCode: '',
      systemId: '1',
      operatorId: userInfo.adminId,
      token: userInfo.token,
      traceId: '',
      clientTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      clientVersion: '1.0.0',
      scene: '',
      sign: ''
    };
  }

  get<T>(path: string, param?: NzSafeAny, config?: HttpCustomConfig): Observable<T> {
    config = config || { needSuccessInfo: false };
    let reqPath = this.getUrl(path, config);
    const params = new HttpParams({ fromString: qs.stringify(param) });
    return this.http.get<ActionResult<T>>(reqPath, { params }).pipe(this.resultHandle<T>(config));
  }

  delete<T>(path: string, param?: NzSafeAny, config?: HttpCustomConfig): Observable<T> {
    config = config || { needSuccessInfo: false };
    let reqPath = this.getUrl(path, config);
    const params = new HttpParams({ fromString: qs.stringify(param) });
    return this.http.delete<ActionResult<T>>(reqPath, { params }).pipe(this.resultHandle<T>(config));
  }

  post<T>(path: string, param?: NzSafeAny, config?: HttpCustomConfig): Observable<T> {
    config = config || { needSuccessInfo: false };
    let reqPath = this.getUrl(path, config);
    return this.http.post<ActionResult<T>>(reqPath, { ...this.getRequestPublicParams(), request: param }).pipe(this.resultHandle<T>(config));
  }

  put<T>(path: string, param?: NzSafeAny, config?: HttpCustomConfig): Observable<T> {
    config = config || { needSuccessInfo: false };
    let reqPath = this.getUrl(path, config);
    return this.http.put<ActionResult<T>>(reqPath, { ...this.getRequestPublicParams(), request: param }).pipe(this.resultHandle<T>(config));
  }

  downLoadWithBlob(path: string, param?: NzSafeAny, config?: HttpCustomConfig): Observable<NzSafeAny> {
    config = config || { needSuccessInfo: false };
    let reqPath = this.getUrl(path, config);
    return this.http.post(
      reqPath,
      { ...this.getRequestPublicParams(), request: param },
      {
        responseType: 'blob',
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      }
    );
  }

  getUrl(path: string, config: HttpCustomConfig): string {
    let reqPath = this.uri + path;
    if (config.otherUrl) {
      reqPath = path;
    }
    return reqPath;
  }

  resultHandle<T>(config: HttpCustomConfig): (observable: Observable<ActionResult<T>>) => Observable<T> {
    return (observable: Observable<ActionResult<T>>) => {
      return observable.pipe(
        filter(item => {
          return this.handleFilter(item, !!config.needSuccessInfo);
        }),
        map(item => {
          if (item.code !== 200) {
            throw new Error(item.msg);
          }
          return item.data;
        })
      );
    };
  }

  handleFilter<T>(item: ActionResult<T>, needSuccessInfo: boolean): boolean {
    if (item.code !== 200) {
      this.message.error(item.msg);
      if (item.code === 506) {
        this.loginService?.loginOut();
      }
    } else if (needSuccessInfo) {
      this.message.success('操作成功');
    }
    return true;
  }
}
